.booking_ticket_primary_btn {
    color: #fff;
    background-color: #dd5467 !important;
    border-color: #dd5467 !important;
}

.booking_ticket_inactive_btn {
    color: #000000 !important;
    background-color: #ffffff !important;
    border-color: #dd5467 !important
}
.booking_ticket_primary_btn:focus {
     box-shadow: 0 0 0 .2rem rgb(237 140 153) !important;
 }

.booking_ticket_primary_btn.disabled, .booking_ticket_primary_btn:disabled {
    color: #000000 !important;
}

.booking_ticket_disabled_date_picker {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
    cursor: not-allowed
}

.booking_ticket_loader_content {
    top: 0;
    left: 0;
}
